import '../style/loader';
import $ from 'jquery';
import '../libs/Magnific-Popup-master/dist/magnific-popup';
require('../libs/Magnific-Popup-master/dist/jquery.magnific-popup.min.js');
import { tns } from 'tiny-slider/src/tiny-slider';

const mobMenuTrigger = $('.mob-menu-trigger');
const mobMenu = $('.navbar__menu_mob');

mobMenuTrigger.on('click', function() {
    mobMenu.slideToggle()
})

$('.open-modal').magnificPopup({
    type:'inline',
    midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
});

var slider = tns({
    container: '.my-slider',
    items: 1,
    slideBy: 'page',
    controls: false,
    mouseDrag: true,
    autoplay: true,
    axis: 'vertical',
    autoplayButtonOutput: false
  });